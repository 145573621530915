.video-background {
    position: relative;
    overflow: hidden;
    width: 25rem;
    height: 25rem;

    pointer-events: none;
    border-radius: 50%;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    filter: brightness(1.4);
}

.video-background:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.8s;
    opacity: 1;
    background-color: var(--white-color);
    background-size: 100% 100%;
  }

.video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-230%, -30%) scale(5, 5);
}
