.app__research {
    flex: 1;
    width: 100%;

    flex-direction: column;
}

    .head-text{
        margin: 0;
    }

    .g-scholar{
        margin-top: 1rem;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        cursor: pointer;
    }

    .app__research-filter {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        flex-wrap: wrap;
        margin: 1rem 0 2rem;

        @media screen and (max-width: 900px) {
            justify-content: center;
            align-items: center;
        }


        .app__research-filter-item {
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            background-color: var(--white-color);
            color: #000;
            font-weight: 800;

            cursor: pointer;

            transition: all 0.3s ease;
            margin: 0.5rem;

            &:hover {
                background-color: var(--secondary-color);
                color: #fff;
            }
        }

        .item-active {
            background-color: var(--secondary-color);
            color: #fff;
        }
    }

    .app__research-portfolio {
        display: flex;
        flex-wrap: wrap;

        justify-content: center;
        align-items: center;

        .app__research-item {
            width: 200px;
            flex-direction: column;
            margin: 1rem;
            padding: 0.5rem;
            border-radius: 0.5rem;
            background-color: var(--white-color);
            color: black;

            cursor: pointer;
            transition: all 0.3s ease;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

            &:hover {
                box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
            }
            @media screen and (max-width: 500px) {
                width: 120px;
                margin: 0.5rem;
                margin-bottom: 1rem;
            }
        }

        .app__research-content {
            padding: 0.5rem;
            width: 100%;

            position: relative;
            flex-direction: column;

            h4 {
                margin-top: 0.5rem;
                line-height: 1;
                text-align: center;
            }

            .app__research-content-year{
                margin-top: 0.1rem;
            }

            .app__research-tag {
                position: absolute;
                padding: 0.5rem 1rem;
                border-radius: 10px;
                background-color: #fff;
                top: -25px;
            }

            .app__research-hover {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                width: 100%;
                height: 100%;

                background-color: rgba(0, 0, 0, 0.5);

                border-radius: 0.5rem;
                opacity: 0;

                transition: all 0.3s ease;

                div {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.5);

                    color: #fff;
                    margin: 1rem;
                    font-family: var(--font-base);

                    font-weight: 800;
                    cursor: pointer;

                    transition: all 0.3s ease;

                    svg {
                        width: 50%;
                        height: 50%;

                        color: var(--white-color);
                    }
                }
            }
        }
    }
