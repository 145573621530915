#home {
    position: relative;
    background: url("../../assets/bgIMG_bn.png");

    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wraper {
        padding: 0;
    }

    .copyright {
        display: none;
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0rem;
        padding-left: 0;
    }

    .app__header-info {
        flex: 1;

        .app__header-badge {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            padding: 0;

            .app__header-info-content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                margin-right: 3rem;

                @media screen and (max-width: 900px) {
                    margin-right: 0rem;
                }

                .badge-cmp {
                    flex-direction: column;
                    align-items: flex-end;
                    width: auto;

                    h1 {
                        padding: 0;
                        margin: 0;

                        width: 100%;
                        text-align: right;

                        font-size: 3rem;
                        text-transform: none;

                        span {
                            font-size: 4rem;
                        }

                        @media screen and (max-width: 2000px) {
                            width: 100%;
                            margin-right: 0rem;
                        }

                        @media screen and (max-width: 900px) {
                            font-size: 2.5rem;
                            padding-top: 2rem;
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 2.5rem;
                            padding-top: 0rem;

                            span {
                                font-size: 3.5rem;
                            }
                        }

                    }

                    @media screen and (max-width: 900px) {
                        padding-left: 0;
                        padding-top: 2rem;
                        width: 100%;
                    }
                }

                .tag-cmp {
                    flex-direction: row;
                    width: auto;

                    flex-direction: column;
                    margin-top: 0.5rem;

                    p {
                        width: 100%;
                        text-transform: uppercase;
                        text-align: right;
                    }
                }
            }
        }
    }

    .app__header-img {
        flex: 1;
        aspect-ratio: 1;
        height: auto;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;


        img {
            width: 300px;
            height: auto;
            aspect-ratio: 1;
            border-radius: 50%;
            object-fit: contain;

            z-index: 1;

            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }

        .overlay_circle {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 0;

            width: 100%;
            height: 90%;
        }

        @media screen and (max-width: 1200px) {
            margin: 2rem 0;
        }

        
    }

    .app__header-circles {
        flex: 0.75;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        height: 100%;
        margin-left: 1rem;
    
        div {
            width: 100px;
            height: 100px;
            border-radius: 50%;
    
            background: var(--white-color);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    
            img {
                width: 60%;
                height: 60%;
            }
        }
    
        div:nth-child(1){
            width: 100px;
            height: 100px;
        }
    
        div:nth-child(2){
            margin: 1.75rem;
            width: 150px;
            height: 150px;
        }
    
        div:nth-child(3){
            width: 70px;
            height: 70px;
        }

    
        @media screen and (max-width: 1200px) {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 0;
    
            div{
                margin: 1rem;
            }
        }   

        @media screen and (max-width: 500px) {
            margin-top: 0rem;
            div{
                margin: 0rem;
            }

            div:nth-child(1){
                width: 70px;
                height: 70px;
            }
        
            div:nth-child(2){
                width: 100px;
                height: 100px;
            }
        
            div:nth-child(3){
                width: 70px;
                height: 70px;
            }
        }
    }
}
