#home {
    position: relative;
    background: url("../../assets/bgIMG_bn.png");

    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wraper {
        padding: 0;
    }

    .copyright {
        display: none;
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0rem;
        padding-left: 0;
    }

    .app__header-info {
        flex: 0.75;


        .app__header-badge {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            padding: 0;

            .app__header-info-content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                margin-right: 2rem;

                .badge-cmp {
                    flex-direction: column;
                    align-items: flex-end;
                    width: auto;

                    h1 {
                        padding: 0;
                        margin: 0;

                        width: 100%;
                        text-align: right;

                        font-size: 3rem;
                        text-transform: none;

                        span {
                            font-size: 4rem;
                        }

                        @media screen and (max-width: 2000px) {
                            width: 100%;
                            margin-right: 0rem;
                        }

                        @media screen and (max-width: 850px) {
                            font-size: 2rem;
                        }

                        @media screen and (max-width: 700px) {
                            font-size: 2.5rem;
                        }
                    }

                    @media screen and (max-width: 850px) {
                        padding-left: 0;
                        padding-top: 4rem;
                        width: 100%;
                    }
                }

                .tag-cmp {
                    flex-direction: row;
                    width: auto;

                    flex-direction: column;
                    margin-top: 0.5rem;

                    p {
                        width: 100%;
                        text-transform: uppercase;
                        text-align: right;
                    }
                }
            }
        }
    }

    .app__header-img {
        flex: 1;
        height: 100%;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        position: relative;

        img {
            width: 100%;
            object-fit: contain;

            z-index: 1;
        }

        .overlay_circle {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 0;

            width: 100%;
            height: 90%;
        }

        @media screen and (max-width: 1200px) {
            margin: 2rem 0;
        }
    }

    .app__header-circles {
        flex: 0.75;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;

        margin: auto;

        @media screen and (max-width: 700px) {
            align-items: flex-start;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 3rem;
        }

        .app__header-circles-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            height: 100%;
            width: 100%;

            .circle-cmp {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin: 1rem;

                background: var(--white-color);
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

                img {
                    width: 60%;
                    height: 60%;
                }
            }
        }

        /*
        .app__header-circles-container:nth-child(3) {
            .circle-cmp:nth-child(1) {
                width: 50%;
                height: auto;
                aspect-ratio: 1;
                max-width: 400px;
            }

            .circle-cmp:nth-child(2) {
                width: 40%;
                height: auto;
                aspect-ratio: 1;
                max-width: 400px;

                position: relative;
                left: 0rem;
            }

            .circle-cmp:nth-child(3) {
                width: 50%;
                height: auto;
                aspect-ratio: 1;
                max-width: 400px;
            }
        }
        */

        /*
            
            @media screen and (min-width: 2000px) {
                div:nth-child(1) {
                    width: 400px;
                    height: 400px;
                }
    
                div:nth-child(2) {
                    width: 170px;
                    height: 170px;
                }
    
                div:nth-child(3) {
                    width: 200px;
                    height: 200px;
                }
            }
    
            @media screen and (max-width: 1200px) {
                div:nth-child(1) {
                    margin: 0;
                    margin-bottom: 1rem;
                    width: 100px;
                    height: 100px;
                }
    
                div:nth-child(2) {
                    margin: 0;
                    width: 170px;
                    height: 170px;
                    margin-bottom: 1rem;
                }
    
                div:nth-child(3) {
                    margin: 0;
                    width: 70px;
                    height: 70px;
                }
            }
        }

            */
    }
}
