.app__experience {
    flex: 1;
    width: 100%;
    flex-direction: column;
    @media screen and (max-width: 500px) {
        .head-text{
            margin-top: 0;
        }
    }
}

.app__experience-container {
    width: 80%;
    margin-top: 2rem;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        width: 90%;
        flex-direction: column;
    }

    @media screen and (max-width: 500px) {
        width: 90%;
        margin-top: 1rem;
    }

    .app__experience-exp-works {
        flex: 1;
        align-items: flex-start;
        justify-content: flex-start;

        .app__experience-exp-work {
            margin-bottom: 2rem;

            .app__experience-exp-work-container{
                display: flex;
                flex-direction: row;

                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;

                .work-year{
                    margin-right: 2rem;

                    @media screen and (max-width: 500px) {
                        margin-right: 1rem;
                    }

                }

                .work-content{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 80%;

                    @media screen and (max-width: 500px) {
                        width: 60%;
                    }

                    h4 {
                        font-weight: 500;
                    }
        
                    p {
                        font-weight: 400;
                        color: var(--gray-color);
                        margin-top: 5px;

                        @media screen and (max-width: 500px) {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                    }
                }

                .work-desc{
                    max-width: 100%;
                    white-space: pre-wrap;
                }
            }
        }
    }
}

.skills-tooltip {
    max-width: 300px !important;
    background-color: var(--white-color) !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 1rem !important;
    color: var(--gray-color) !important;
    text-align: left !important;
    line-height: 1.5 !important;
    opacity: 1 !important;

    position: absolute !important;
    top:0 !important;
    bottom:0 !important;
    left:0 !important;
    right:0 !important;

    white-space: pre-wrap;

    @media screen and (min-width: 2000px) {
        font-size: 1.75rem !important;
        max-width: 500px !important;
        line-height: 2 !important;
    }
}
