.app__projects {
    flex: 1;
    width: 100%;

    flex-direction: column;

    @media screen and (max-width: 500px) {
        .head-text{
            margin-top: 0;
        }
    }
}

.app__projects-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    flex-wrap: wrap;

    .app__projects-item {
        width: 150px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: var(--white-color);
        height: 220px;

        cursor: pointer;
        margin: 2rem;

        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        
        text-decoration: none;

        @media screen and (max-width: 500px) {
            width: 100px;
            height: 180px;
            margin: 1rem;
        }
        
        img {
            width: 100%;

            height: 80px;
            border-radius: 15px;
            object-fit: cover;
        }

        h2{
            margin-top: 20px;

            @media screen and (max-width: 500px) {
                margin-top: 0;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            } 
        }

        .p-text {
            padding: 0;
            margin: 0;
            font-size: 0.7rem;

            text-decoration: none; 
            @media screen and (max-width: 500px) {
                font-size: 0.5rem;
            }
        }

        h2 {
            font-size: 0.9rem;
            margin-bottom: 0.5rem;

            text-decoration: none;
        }

        @media screen and (min-width: 2000px) {
            width: 370px;
            margin: 2rem 4rem;

            img {
                height: 320px;
            }
        }
    }
}
