.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        .head-text{
            margin-top: 0;
        }
    }
}

.app__skills-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 900px) {
        width: 100%;
        flex-direction: column;
    }

    .app__skills-exp {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        @media screen and (max-width: 900px) {
            margin-top: 2rem;
        }

        @media screen and (max-width: 500px) {
            margin-top: 0rem;
        }

        .app__skills-exp-item {
            min-width: 200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1rem;
            height: 150px;
            
            border-radius: 20px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

            background-color: var(--primary-color);

            @media screen and (max-width: 500px) {
                min-width: none;
                height: 100px;
            } 

            .app__skills-exp-year {
                h4 {
                    font-weight: 800;
                    color: var(--secondary-color);
                    margin-top: 1rem;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    font-size: 1.5rem;
                }

                @media screen and (max-width: 500px) {
                    h4 {
                        font-weight: 800;
                        color: var(--secondary-color);
                        margin-top: 0.5rem;
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                        font-size: 1rem;
                    }
                }
            }

            .app__skills-exp-works {
                flex: 1;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 1rem;
            
                .app__skills-exp-work {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    cursor: pointer;
                }

                .container_image {
                    width: 60px;
                    height: auto;
                    aspect-ratio: 1;
                    
                    flex-wrap: wrap;
                    display: flex;
                    align-self: center;
                    justify-content: center;
    
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    
                    background-color: var(--primary-color);
                    border-radius: 50%;

                    transition: all 0.3 ease-in-out;
    
                    &:hover {
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    }
    
                    img {
                        display: flex;
                        align-self: center;
                        justify-content: center;
    
                        width: 60%;
                        height: 60%;
                    }

                    @media screen and (max-width: 500px) {
                        width: 40px;
                    }
                }
            }

           
        }
    }
}


